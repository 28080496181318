import * as action from './actions';

import * as actionTypes from './actionTypes';

import { DEFAULT_COUNTRY } from 'constants/countriesList';

import { LS_KEYS } from 'helpers/localStorage';

import { UserStore } from 'types/store/userStore';
import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: UserStore = {
    email: null,
    orderId: null,
    user_id: null,
    emailStatus: {
        isValid: false,
        message: '',
    },
    isLoading: false,
    token: null,
    payment_type: null,
    guideId: null,
    loader: false,
    country: localStorage.getItem('country') || DEFAULT_COUNTRY,
    lastUpdateAt: null,
    isPaid: false,
    platform: null,
    isAutologinComplete: false,
    deepLink: null,
    isPersonalPlan: false,
};

const UserReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SIGN_UP_FAIL:

        case actionTypes.UPDATE_INFO_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case actionTypes.SET_EMAIL:
            return { ...state, email: action.payload };

        case actionTypes.SET_GUIDE_ID:
            return { ...state, guideId: action.payload };

        case actionTypes.SET_USER_TOKEN:
            localStorage.setItem(LS_KEYS.token, action.payload);

            return { ...state, token: action.payload };

        case actionTypes.SET_COUNTRY:
            localStorage.setItem('country', action.payload);

            return { ...state, country: action.payload };

        case actionTypes.SET_IS_PAID:
            return { ...state, isPaid: action.payload };

        case actionTypes.SET_UPDATED_AT_TIME:
            return { ...state, lastUpdateAt: action.payload };

        case actionTypes.SET_IS_AUTOLOGIN_COMPLETE:
            return { ...state, isAutologinComplete: action.payload };

        case actionTypes.SET_PLATFORM:
            return { ...state, platform: action.payload };

        case actionTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };

        case actionTypes.GET_DEEP_LINK_SUCCESS:
            return { ...state, deepLink: action.payload, isLoading: false };

        case actionTypes.SET_IS_PERSONAL_PLAN:
            return { ...state, isPersonalPlan: action.payload };

        default:
            return state;
    }
};

export default UserReducer;
