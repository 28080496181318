import * as actionTypes from './actionTypes';

import { ActivityLevels, FitnessLevel } from 'constants/onboardingFlow';

import { OnboardingScreensList, MeasurePayload, OnboardingStore } from 'types/onboarding/onboardingStore';
import { IGender, MeasureSystemType } from 'types/commonInterfaces';

import { Dayjs } from 'dayjs';

export function setUrlParams(url: { [key: string]: string } | null) {
    return {
        type: actionTypes.SET_URL_PARAMS,
        payload: url,
    } as const;
}

export function setGender(token: IGender) {
    return {
        type: actionTypes.SET_GENDER,
        payload: token,
    } as const;
}

export function setGoal(token: string[] | null) {
    return {
        type: actionTypes.SET_GOAL,
        payload: token,
    } as const;
}

export function setSleepDuration(token: string) {
    return {
        type: actionTypes.SET_SLEEP_DURATION,
        payload: token,
    } as const;
}

export function setCurrentBody(body_type: string) {
    return {
        type: actionTypes.SET_CURRENT_BODY,
        payload: body_type,
    } as const;
}

export function setCurrentWeight(current_weight_with_unit: MeasurePayload) {
    return {
        type: actionTypes.SET_CURRENT_WEIGHT,
        payload: current_weight_with_unit,
    } as const;
}

export function setTargetBody(body_type: string) {
    return {
        type: actionTypes.SET_TARGET_BODY,
        payload: body_type,
    } as const;
}

export function setTargetZone(targetZones: string[]) {
    return {
        type: actionTypes.SET_TARGET_ZONE,
        payload: targetZones,
    } as const;
}

export function setTargetWeight(weight_with_unit: MeasurePayload) {
    return {
        type: actionTypes.SET_TARGET_WEIGHT,
        payload: weight_with_unit,
    } as const;
}

export function setActivityLvl(activityLevel: ActivityLevels) {
    return {
        type: actionTypes.SET_ACTIVITY_LVL,
        payload: activityLevel,
    } as const;
}

export function setMeasure(system: MeasureSystemType) {
    return {
        type: actionTypes.SET_MEASURE_SYSTEM,
        payload: system,
    } as const;
}

export function setAge(age: string | number) {
    return {
        type: actionTypes.SET_AGE,
        payload: age,
    } as const;
}

export function setAgeRange(data: Record<string, string | number>) {
    return {
        type: actionTypes.SET_AGE_RANGE,
        payload: data,
    } as const;
}

export function setTall(tall: MeasurePayload) {
    return {
        type: actionTypes.SET_TALL,
        payload: tall,
    } as const;
}

export function setOnboardingScreensList(screensList: OnboardingScreensList) {
    return {
        type: actionTypes.SET_ONBOARDING_SCREENS_LIST,
        payload: screensList,
    } as const;
}

export function initAutoLogin() {
    return {
        type: actionTypes.INIT_AUTO_LOGIN,
    } as const;
}

export function setOccasion(occasion: string) {
    return {
        type: actionTypes.SET_OCCASION,
        payload: occasion,
    } as const;
}

export function setOccasionDate(occasion_date: string) {
    return {
        type: actionTypes.SET_OCCASION_DATE,
        payload: occasion_date,
    } as const;
}

export function setFitnessLvl(fitnessLevel: FitnessLevel) {
    return {
        type: actionTypes.SET_FITNESS_LVL,
        payload: fitnessLevel,
    } as const;
}

export function skipOnboarding(payload: { reload: () => void }) {
    return {
        type: actionTypes.SKIP_ONBOARDING,
        payload,
    } as const;
}

export function setSkipOnboardingState(payload: OnboardingStore) {
    return {
        type: actionTypes.SET_SKIP_ONBOARDING_STATE,
        payload,
    } as const;
}

export function setCurrentMood(current_mood: string) {
    return {
        type: actionTypes.SET_CURRENT_MOOD,
        payload: current_mood,
    } as const;
}

export function setStressFactors(stress_factors: string[]) {
    return {
        type: actionTypes.SET_STRESS_FACTORS,
        payload: stress_factors,
    } as const;
}

export function setSleepProblems(sleep_problems: string[]) {
    return {
        type: actionTypes.SET_SLEEP_PROBLEMS,
        payload: sleep_problems,
    } as const;
}

export function setOccasionResultConfig(data: { targetDate: Dayjs; weightList: number[]; monthsArray: string[] }) {
    return {
        type: actionTypes.SET_OCCASION_RESULT_CONFIG,
        payload: data,
    } as const;
}
