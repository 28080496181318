import bindGlobalStyles from 'helpers/bindGlobalStyles';

import base from 'assets/styles/Common.module.css';
import styles from './Container.module.css';

import { ContainerTypes } from './types';

const { cx, globalStyles } = bindGlobalStyles(styles, base);

function Container({ globalClassNames = [], className = '', children, full, noBottomPadding, solo }: ContainerTypes) {
    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const containerClass = cx(
        'default',
        {
            withoutHeader: solo,
            fullPadding: full,
            noBottomPadding,
        },
        classes,
        className
    );
    const style = {
        minHeight: window.innerHeight + 'px',
    };

    return (
        <div style={style} className={containerClass}>
            {children}
        </div>
    );
}

export default Container;
