import { BrainTreeSubscriptionPayload } from 'api/types/request';

import { ValidateResponse } from 'types/payments/validate';
import {
    UpsaleData,
    PaymentData,
    IPaymentFailToReteno,
    IPaymentFailToRetenoResponse,
    IValidateData,
    IReplaceSubscription,
} from 'types/payments/paymentApiInterfaces';

import Base from './Base';

class PaymentApi extends Base {
    async getBraintreeToken() {
        return await this.apiClient.private.get<string>('braintree/get-token');
    }

    async brainTreeSubscription<Data = BrainTreeSubscriptionPayload, Response = PaymentData>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('braintree/subscription', payload);
    }

    async upSale<Data = UpsaleData, Response = PaymentData>(payload: Data): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('payments/recurring', payload);
    }

    async paymentFailToReteno<Data = IPaymentFailToReteno, Response = IPaymentFailToRetenoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('reteno/event', payload);
    }

    async postInit<Data = IValidateData, Response = ValidateResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('payments/post-init', payload);
    }

    async replaceSubscription<Data = IReplaceSubscription, Response = PaymentData>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('replace-subscription', payload);
    }
}

export default PaymentApi;
