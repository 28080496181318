import { InitPayPal } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { takeLatest, put } from 'redux-saga/effects';

import * as actionTypes from 'redux/payment/actionTypes';
import { setInitPaymentData, setOrderId } from 'redux/payment/actions';

import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { ActionType } from 'types/commonInterfaces';

export function* init({ payload }: ActionType<InitPayPal>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    try {
        const response: PaymentData = yield Solid.init('payPal', payload);

        yield put(setInitPaymentData(response));
        yield put(setOrderId(response?.order?.order_id));
    } catch (e) {
        console.error('Error init');
    }
}

export const initPayPal = [takeLatest(actionTypes.INIT_PAY_PAL, init)];
