/* eslint-disable max-lines */
import { SENTRY_AXIOS, ERROR_LEVELS } from 'sentry-utils';
import axios, { AxiosInstance } from 'axios';

import sentry from 'services/Sentry/SentryInstance';

import { deleteConfig, LS_KEYS } from 'helpers/localStorage';

import { RequestOptions } from './types/request';

import config from '../config';

const PLATFORM_CODE = 3;

export default class ApiClient {
    authToken: string | null;
    apiUrl: string;
    apiVersion: string;
    apiKey: string;
    isSandbox: boolean;
    isPrivate: boolean;

    constructor({ apiUrl = config.API_URL, isSandbox = false, isPrivate = false }) {
        this.authToken = localStorage.getItem(LS_KEYS.token) ? localStorage.getItem(LS_KEYS.token) : '';
        this.apiUrl = apiUrl;
        this.apiVersion = config.API_VERSION;
        this.apiKey = config.API_KEY;
        this.isSandbox = isSandbox;
        this.isPrivate = isPrivate;
    }

    async get<ResponseData>(url: string, params?: Record<string, string>, headers?: Record<string, string | boolean>) {
        return this.request<unknown, ResponseData>({
            url,
            params,
            headers,
            method: 'GET',
            data: {},
        });
    }

    async post<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData, ResponseData>({
            url,
            method: 'POST',
            data: payload,
        });
    }

    async put<RequestData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData>({
            url,
            method: 'PUT',
            data: payload,
        });
    }

    async patch<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData, ResponseData>({
            url,
            method: 'PATCH',
            data: payload,
        });
    }

    setAuthToken(authToken: string) {
        this.authToken = authToken;
    }

    getToken() {
        return localStorage.getItem(LS_KEYS.token);
    }

    getLanguage() {
        return localStorage.getItem('language') || 'en';
    }

    async request<RequestData, ResponseData = unknown>(
        options: RequestOptions<RequestData>
    ): Promise<ResponseData | unknown> {
        const tmpOptions = { ...options, url: `/${options.url}` };

        const axiosInstance: AxiosInstance = axios.create({
            baseURL: this.apiUrl,
        });

        const token = this.getToken();

        token && this.setAuthToken(token);

        if (!this.authToken && this.isPrivate) {
            sentry.logError(new Error('Required token is missing'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                label: 'Token Required',
            });

            console.error('Required token is missing');

            return Promise.reject('Token Required');
        }

        axiosInstance.defaults.headers.common['version'] = this.apiVersion;
        axiosInstance.defaults.headers.common['x-api-key'] = this.apiKey;
        axiosInstance.defaults.headers.common['language'] = this.getLanguage();
        axiosInstance.defaults.headers.common['platform'] = PLATFORM_CODE;
        axiosInstance.defaults.headers.common['screenId'] = window.location.pathname.replace('/', '');

        const { common } = axiosInstance.defaults.headers;

        if (!common['version'] || !common['x-api-key'] || !common['language'] || !common['platform']) {
            sentry.logError(new Error('Required header is missing'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                ...common,
            });

            console.error('Required header is missing');

            return Promise.reject('Required header is missing');
        }

        if (!tmpOptions.params) {
            tmpOptions.params = {};
        }

        if (this.authToken && this.isPrivate) {
            tmpOptions.headers = {
                ...tmpOptions.headers,
                token: this.authToken,
            };
        }

        if (this.isSandbox) {
            tmpOptions.headers = {
                ...tmpOptions.headers,
                sandbox: this.isSandbox,
            };
        }

        tmpOptions.headers = {
            ...tmpOptions.headers,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axiosInstance(tmpOptions);

            if (response.headers.token) response.data.token = response.headers.token;
            if (response.headers.country) response.data.country = response.headers.country;

            return Promise.resolve(response.data);
        } catch (error) {
            const status = error?.response?.status;

            if (status === 403) {
                deleteConfig();

                if (location.search) history.pushState(null, '', location.origin);

                location.reload();
            }

            sentry.logError(error, SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, { ...tmpOptions, ...error?.response });

            console.error('Server Error', error?.response || '');

            return Promise.reject(error?.response?.data || 'Untracked error');
        }
    }
}
