import { FunctionComponent, SVGProps } from 'react';

import { IButtonData, IImageData } from 'types/pages/onboardingPages/commonTypes';

export enum OptionSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    ExtraLarge = 'extraLarge',
}

export interface IOptionDes {
    rightSvg?: FunctionComponent<SVGProps<SVGSVGElement>>;
    rightImage?: string;
    isCheckbox?: boolean;
    onClick?: (value: string, checked: boolean) => void;
    title?: string;
    subtitle?: string;
    value: string;
    dataLocator?: string;
    mb?: number;
    size?: OptionSize;
    tiled?: boolean;
}

export interface IOptionContentDesTypes {
    buttonData?: IButtonData;
    forbiddenValue?: string;
    imageData?: IImageData;
    optionData: IOptionDes[];
    subtitle?: string;
    title?: string;
}
