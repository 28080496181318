import { createContext, useContext } from 'react';

import useOneTrustLoaded from 'hooks/useOneTrustLoaded';

const OneTrustContext = createContext(null);

export const OneTrustProvider = ({ children }) => {
    const { oneTrust } = useOneTrustLoaded();

    return <OneTrustContext.Provider value={{ oneTrust }}>{children}</OneTrustContext.Provider>;
};

export const useOneTrust = () => useContext(OneTrustContext);
