import { COOKIE_ANALYTIC_LIST } from 'constants/analytics';

export const getAnalyticInitList = (cookieIdList) => {
    const analyticInitList = [];

    cookieIdList.forEach((cookieId) => {
        const cookieAnalytics = COOKIE_ANALYTIC_LIST[cookieId];

        cookieAnalytics && analyticInitList.push(...cookieAnalytics);
    });

    return analyticInitList;
};

export const getAllAnalyticInitList = () =>
    Object.values(COOKIE_ANALYTIC_LIST).reduce((acc, analytic) => [...analytic], []);
