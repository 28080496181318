import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { selectTestaniaName, selectCurrentBranchName } from 'redux/testania/selectors';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Component } from 'react';

import { setAppLoader } from 'redux/uiEffects/actions';
import { RootState } from 'redux/types';

import sentry from 'services/Sentry/SentryInstance';

import { ErrorFallback } from 'modules/ErrorHandler/ErrorFallback';
import { Props, State } from './interface';

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
        };
    }

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public logError(error: Error) {
        const { testaniaName, currentBranchName } = this.props;

        sentry.logError(error, SENTRY_APP, ERROR_LEVELS.CRITICAL, { testaniaName, currentBranchName });
    }

    public componentDidCatch(error: Error) {
        this.props.setAppLoader();

        this.setState({ error });

        this.logError(error);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorFallback />;
        }

        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setAppLoader: () => dispatch(setAppLoader(false)),
});

const mapStateToProps = (state: RootState) => {
    return {
        testaniaName: selectTestaniaName(state),
        currentBranchName: selectCurrentBranchName(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
