import { HEIGHT_CONSTRAINTS, WEIGHT_CONSTRAINTS } from 'constants/validationConstraints';
import { IMPERIAL } from 'constants/measureUnits';

import { MeasureSystemType } from 'types/commonInterfaces';

export const getDefaultWeightAndHeight = (measure: MeasureSystemType) => {
    const { poundMinValue, kilogramMinValue } = WEIGHT_CONSTRAINTS;
    const { singleFeetMinValue, centimetersMinValue } = HEIGHT_CONSTRAINTS;

    const weight = measure === IMPERIAL ? poundMinValue : kilogramMinValue;
    const height = measure === IMPERIAL ? singleFeetMinValue : centimetersMinValue;

    return {
        weight,
        height,
        target_weight: weight,
    };
};
