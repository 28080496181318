import ApiClient from '../apiClient';

class Base {
    apiClient: ApiClient;

    constructor({ apiClient }: { apiClient: ApiClient }) {
        if (!apiClient) throw new Error('[apiClient] required');

        this.apiClient = apiClient;
    }
}

export default Base;
