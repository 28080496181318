import classNames from 'classnames/bind';

import { Text } from 'libComponents';
import LazyImage from 'components/LazyImage';

import styles from './PersonalFeedback.module.css';

import type { IPersonalFeedback } from './types';
import { PersonalFeedbackBgColor } from './types';

const cx = classNames.bind(styles);

function PersonalFeedback({
    image,
    icon,
    title,
    feedback,
    titleSlogan,
    bgColor = PersonalFeedbackBgColor.DEFAULT,
    className,
    sloganTitleClassName,
    titleClassName,
}: IPersonalFeedback) {
    const containerStyles = cx('container', className, { [bgColor]: Boolean(bgColor) });
    const sloganTitleStyles = cx('sloganDefault', sloganTitleClassName);

    return (
        <div className={containerStyles}>
            <div className={styles.imageWrapper}>
                {image && <LazyImage src={image} alt="feedback icon" />}
                {icon && icon}
            </div>
            <div>
                <Text type="caption" bold mb={6}>
                    {titleSlogan && (
                        <>
                            <span className={sloganTitleStyles}>{titleSlogan}</span>
                            <span>: </span>
                        </>
                    )}
                    <span className={titleClassName}>{title}</span>
                </Text>
                <Text type="caption">{feedback}</Text>
            </div>
        </div>
    );
}

export default PersonalFeedback;
