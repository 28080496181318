export const HEIGHT_CONSTRAINTS = {
    feetMinValue: 4,
    feetMaxValue: 7,
    inchMinValue: 0,
    inchMaxValue: 11,
    centimetersMinValue: 100,
    centimetersMaxValue: 250,
    singleFeetMinValue: 121.92,
    singleInchMinValue: 15,
    singleInchMaxValue: 98,
};

export const WEIGHT_CONSTRAINTS = {
    poundMinValue: 66,
    poundMaxValue: 329,
    kilogramMinValue: 30,
    kilogramMaxValue: 250,
};

export const AGE_CONSTRAINTS = {
    userMinAge: 16,
    userMaxAge: 99,
};
