export const otherRoutes = [
    {
        path: 'GeneralPages/Registration',
        route: 'registration-page',
    },
    {
        path: 'GeneralPages/ThankYou',
        route: 'thank-you',
    },
];
