/* eslint-disable max-lines */
import { AnalyticUtils, uaParser } from 'wikr-core-analytics';
import { useTranslation } from 'react-i18next';
import { KeyboardEvent } from 'react';
import { FormikValues } from 'formik';

import config from 'config';

import { PROCESSING_FEE_COEF, WALLPILATES_BRANCH_LIST } from 'constants/values';
import { FitnessLevel, ProblemZone, RETENO_TARGET_ZONES } from 'constants/onboardingFlow';
import { MEASURE_SYSTEM } from 'constants/measureUnits';
import { DEFAULT_LANGUAGE } from 'constants/localization';
import { DEFAULT_FLOW_LINK } from 'constants/defaultFlows';

import { LS_KEYS } from 'helpers/localStorage';
import lazyLoad from 'helpers/lazyWithRetry';

import { CurrentProduct } from 'types/payments/payments';
import { MeasureSystemType } from 'types/commonInterfaces';
import { PreloadType } from './lazyWithRetry/types';

import { getSubscriptionTitle } from './subscriptions';
import Convert from './Convert';

const convertUnits = new Convert();
const UAParser = new uaParser();

const WIDTH_SIZE = 2000;
const HEIGHT_SIZE = 2000;

export const getKgWeight = (data: { unit: string; value: number }) => {
    const { unit, value } = data;

    return unit === 'lbs' ? convertUnits.fromLbsToKg(value) : value;
};

export const getNumberSystem = (unit: string): MeasureSystemType => {
    return MEASURE_SYSTEM[unit] || 'IMPERIAL';
};

export const deleteSlash = (word: string) => word.replace(/\//g, '');

export const cleanObject = <T>(object: Record<string, unknown>): Record<string, unknown> | T => {
    for (const propName in object) {
        if (
            object[propName] === null ||
            object[propName] === undefined ||
            (typeof object[propName] === 'number' && isNaN(object[propName] as number))
        ) {
            delete object[propName];
        }
    }

    return object;
};

export const getRelease = () => {
    return JSON.parse(config.RELEASE);
};

export const joinClasses = (...arg: string[]) => {
    return [...arg].join(' ');
};

export const replaceUnderscore = (string: string) => string.replace(/_/g, '-');

export const fromPennyToInt = (penny: number) => penny / 100;

export const getPixelAnalyticID = () => config.PIXEL_ID;

export const isAttributionFlow = () => {
    const attributionsList = ['attribution1', 'attribution2', 'attribution3', 'attribution4'];
    const pathname = deleteSlash(window.location.pathname);

    const urlParamsStorage = localStorage.getItem('urlParams');

    const urlParams = urlParamsStorage && JSON.parse(urlParamsStorage);

    const pageType = urlParams?.page_type;

    return attributionsList.includes(pathname) || pageType === 'attribution';
};

export const getDeviceOS = () => {
    const userAgent = window.navigator.userAgent;
    const getOSData = UAParser.setUA(userAgent).getOS();

    return getOSData?.name?.toUpperCase() || 'DEFAULT_DEVICE';
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const updateGlobalLoaderVisibility = (isVisible: boolean) => {
    const globalLoader = document.getElementById('app-loader');

    if (globalLoader) globalLoader.style.display = isVisible ? 'block' : 'none';
};

export const getFaviconElement = (id = 'favicon') => {
    return document.getElementById(id);
};

export const getParamFromUrl = (paramName: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

const getPaymentType = (currentProduct: CurrentProduct | null) => {
    if (currentProduct?.trial === currentProduct?.period || currentProduct?.trial === 0) {
        return 'SEVERAL_MONTH_PLAN';
    } else {
        return 'ONE_WEEK_PLAN';
    }
};

export const getProductPaymentData = (currentProduct: CurrentProduct | null, isTrial?: boolean) => {
    const currentPaymentType = getSubscriptionTitle(currentProduct?.trial, isTrial);
    const paymentType = getPaymentType(currentProduct);
    const paymentLabel = getSubscriptionTitle(currentProduct?.period, isTrial);
    const fullPrice = getPriceFromCents(currentProduct?.start_price);
    const trialPrice = getPriceFromCents(currentProduct?.price);
    const trialLabel = '7-day trial';

    return { paymentType, currentPaymentType, paymentLabel, fullPrice, trialPrice, trialLabel };
};

export const getDiscount = (oldPrice: number, newPrice: number) => {
    return (((oldPrice - newPrice) / oldPrice) * 100).toFixed();
};

export const sortProductsByDiscount = (productsArr: Array<CurrentProduct>) =>
    productsArr.sort((a, b) => {
        const aDiscount: number = +getDiscount(a.start_price, a.price);
        const bDiscount: number = +getDiscount(b.start_price, b.price);

        return bDiscount - aDiscount;
    });

export const getPriceWithDecimals: (price: number, decimals?: number) => string = (price, decimals = 2) => {
    const priceValue = price / 100;

    return priceValue.toFixed(decimals);
};

export const getPriceFromCents = (price?: number) => {
    const priceValue = Number(price);

    return getToFixedNumber(priceValue / 100);
};

export const getPriceExclusiveOffer = (fullPrice: number) => Math.round((fullPrice / 7) * 100) / 100;

export const ageToDate = (age: string | number) => {
    const today = new Date();

    return `${today.getFullYear() - Number(age)}-01-01`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyWithPreload(factory: any) {
    const Component = lazyLoad({ importer: factory, preloadType: PreloadType.NextPage });

    // @ts-ignore
    Component.preload = factory;

    return Component;
}

// TODO: refactor
export const getToFixedNumber = (number: number, numbersAfterComma = 2) => {
    const numberValue = Number(number);

    return Number(numberValue.toFixed(numbersAfterComma));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isString = (value: any) => typeof value?.valueOf() === 'string';

export const isObject = (value: unknown) => {
    const type = typeof value;

    return value !== null && (type === 'object' || type === 'function');
};

export const getOnlyPositiveNumber = (digit: number) => {
    return digit <= 0 ? 1 : digit;
};

export const enableOnlyDigitsInput = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
        return;
    }
    if (event.key < '0' || event.key > '9') {
        event.preventDefault();
    }
};

export const getTrimmedFields = (fields: FormikValues) => {
    Object.keys(fields).forEach((k) => {
        if (typeof fields[k] === 'string') {
            fields[k] = fields[k].trim();
        }
    });

    return fields;
};

export const isShortPayment = () => {
    const configStorage = localStorage.getItem(LS_KEYS.config);

    const payment_flow = configStorage && JSON.parse(configStorage)?.payment_flow;

    if (isEmpty(payment_flow)) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const id = payment_flow?.find(({ parent_id }: any) => parent_id === null)?.id;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return payment_flow?.some(({ parent_id, is_paid }: any) => parent_id === id && !is_paid);
};

export const getDiscountForPayment = (currentProduct: CurrentProduct) => {
    const startPrice = currentProduct?.start_price;
    const productPrice = currentProduct?.price;

    return Math.round((1 - productPrice / startPrice) * 100);
};

export const generateQueryParams = () => {
    const urlParamsStorage = localStorage.getItem('urlParams');

    const defaultUrlParams = AnalyticUtils.getDefaultUrlParams();

    const params = urlParamsStorage ? JSON.parse(urlParamsStorage) : defaultUrlParams;

    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

    return searchParams.toString();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromEntriesPolyfill = (iterable: any) => {
    return [...iterable].reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {});
};

export const getUrlParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)?.entries();

    const params = fromEntriesPolyfill(urlSearchParams);

    return isEmpty(params) ? null : params;
};

export const translate = (key: string) => {
    const { t } = useTranslation();

    return t(key);
};

export const getWindowSize = (params = '(min-width: 1024px)') => {
    const mediaQuery = window.matchMedia(params);

    return mediaQuery.matches;
};

export const getLanguage = () => localStorage.getItem('language') || DEFAULT_LANGUAGE;

export const getBranchNameForBERequest = (branchName: string) => {
    if (WALLPILATES_BRANCH_LIST.find((item) => item === branchName)) return 'wallpilates';

    return branchName;
};

export const checkFbBots = () => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === WIDTH_SIZE && windowHeight === HEIGHT_SIZE;
};

export const formattedUrlHelper = (url: string) => url.replace(/(\/([a-z]{2})\/)|\//g, '');

export const snakeCaseToSpace = (value: string) => value?.split('_')?.join(' ');

export const replaceUnderscoreWithSpace = (str: string): string => str.replace(/_/g, ' ');

export const mapRetenoTargetZones = (zones: ProblemZone[]): ProblemZone[] =>
    zones.map((zone: ProblemZone) => RETENO_TARGET_ZONES[zone] ?? zone);

export const processingFeeConvertor = (value: number | string) =>
    Number((Number(value) / PROCESSING_FEE_COEF).toFixed(2));

export const isProd = () => config.ENV === 'prod';

export const generateRandomIntBetween = (min: number, max: number): number => {
    return Math.round(Math.random() * (max - min) + min);
};

export const getTranslationSlide = (slides: { [key: string]: string[] }) => {
    const lang = getLanguage();

    return slides[lang] ?? slides['en'];
};

export const isLocalhost = () => window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

// fix of local problem with SSRF
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replaceDataWithLocalhost = (data: any) => {
    Object.entries(data).map(([fieldName, fieldValue]) => {
        if (isString(fieldValue)) {
            if ((fieldValue as string).includes('localhost')) {
                data[fieldName] = DEFAULT_FLOW_LINK;
            }
        }

        if (isObject(fieldValue)) {
            replaceDataWithLocalhost(fieldValue);
        }
    });
};

export const capitalizeFirstLetter = (word: string) => {
    if (word.length === 0) {
        return word;
    }

    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const isEmptyString = (value: string) => value.trim().length === 0;

export const normalizeFitnessLevel = (fitnessLevel: FitnessLevel) =>
    fitnessLevel === FitnessLevel.Experienced ? FitnessLevel.Beginner : fitnessLevel;

export const getBranchName: {
    (defaultValue: string): string;
    (defaultValue?: null): null;
} = (defaultValue?: any) => localStorage.getItem(LS_KEYS.currentBranchName) ?? defaultValue;
