import classNames from 'classnames/bind';

import { Box } from 'libComponents';

import styles from './styles.module.css';

import type { ContainerI } from './types';

const cx = classNames.bind(styles);

const Container = ({ children, className, isFixedButton = false }: ContainerI) => (
    <Box className={cx('container', className, { containerFixedButton: isFixedButton })}>{children}</Box>
);

export default Container;
