export enum ActivityLevels {
    SEDENTARY = 'sedentary',
    SOMEWHAT_ACTIVE = 'somewhatActive',
    MODERATELY_ACTIVE = 'moderatelyActive',
    VERY_ACTIVE = 'veryActive',
    HIGHLY_ACTIVE = 'highlyActive',
}

export enum FitnessLevel {
    Beginner = 'beginner',
    Experienced = 'experienced',
    Intermediate = 'intermediate',
    Advanced = 'advanced',
}

export enum ProblemZone {
    Shoulders = 'shoulders',
    Buttocks = 'buttocks',
    Back = 'back',
    Arms = 'arms',
    Legs = 'legs',
    Belly = 'belly',
    Core = 'core',
    Chest = 'chest',
    Face = 'face',
    Elbows = 'elbows',
    Wrists = 'wrists',
    Feet = 'feet',
    Breasts = 'breasts',
    Pecs = 'pecs',
    Neck = 'neck',
    Knees = 'knees',
    LowerBack = 'lower_back',
    WholeBody = 'whole_body',
}

export const RETENO_TARGET_ZONES: Partial<Record<ProblemZone, ProblemZone>> = {
    [ProblemZone.Pecs]: ProblemZone.Chest,
    [ProblemZone.Breasts]: ProblemZone.Chest,
    [ProblemZone.Knees]: ProblemZone.Legs,
    [ProblemZone.LowerBack]: ProblemZone.Back,
};

export enum FitnessGoal {
    LoseWeight = 'lose_weight',
    ImproveHeartHealth = 'improve_heart_health',
    GetFirmAndToned = 'get_firm_and_toned',
    RelieveStress = 'relieve_stress',
    ReleaseStoredEmotions = 'release_stored_emotions',
    StressAnxiety = 'stress_anxiety',
    Pain = 'pain',
    Flexibility = 'flexibility',
}
