/* eslint-disable max-lines */
import { systemNames } from 'wikr-core-analytics';
import { isOneMonthProduct } from 'helpers/testania';
import { GENERAL_BRANCH } from 'constants/testania';

import { DEFAULT_BMI } from 'constants/values';
import { CLP } from 'constants/payments/currency';
import {
    DAYS_PER_ELEVEN_MONTH,
    DAYS_PER_TEN_MONTH,
    DAYS_PER_NINE_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_WEEK,
    DAYS_PER_YEAR,
    HARDCODED_PRICE_FOR_FIRST_TRIAL_PRODUCT_ON_PAYMENTG4FOXTROT16,
    CREDIT_CARD_LITERAL,
    ONE_CLICK_LITERAL,
} from 'constants/payments';
import { LTV_COEFFICIENT_KEY, DEFAULT_LTV_COEFFICIENT } from 'constants/ltv';

import { getDiscount, getPriceFromCents } from '../utils';

import { LS_KEYS } from 'helpers/localStorage';
import Convert from 'helpers/Convert';
import { excludeAnalyticsSystems } from 'helpers/analytics';

import { CurrentProduct, CurrentProduct as ICurrentProduct } from 'types/payments/payments';
import { MeasureSystemType } from 'types/commonInterfaces';
import { IGetContentId, GetPurchaseOptionsType } from './types';

import { isAndroid, isIOS } from '../devices';

export const getSubscriptionTitle = (product: ICurrentProduct | number, isTrial?: boolean) => {
    const period = typeof product === 'object' ? product.period : product;

    const PERIODS_ARR = [
        { period: 1, count: 1, context: 'day' },
        { period: 2, count: 2, context: 'day' },
        { period: 3, count: 3, context: 'day' },
        { period: 4, count: 4, context: 'day' },
        { period: 5, count: 5, context: 'day' },
        { period: 6, count: 6, context: 'day' },
        { period: DAYS_PER_WEEK, count: isTrial ? 7 : 1, context: isTrial ? 'day' : 'week' },
        { period: 8, count: 8, context: 'day' },
        { period: 9, count: 9, context: 'day' },
        { period: 10, count: 10, context: 'day' },
        { period: 11, count: 11, context: 'day' },
        { period: 12, count: 12, context: 'day' },
        { period: 13, count: 13, context: 'day' },
        { period: 14, count: 14, context: 'day' },
        { period: DAYS_PER_MONTH, count: 1, context: 'month' },
        { period: DAYS_PER_TWO_MONTH, count: 2, context: 'month' },
        { period: DAYS_PER_THREE_MONTH, count: 3, context: 'month' },
        { period: DAYS_PER_FOUR_MONTH, count: 4, context: 'month' },
        { period: DAYS_PER_FIVE_MONTH, count: 5, context: 'month' },
        { period: DAYS_PER_SIX_MONTH, count: 6, context: 'month' },
        { period: DAYS_PER_SEVEN_MONTH, count: 7, context: 'month' },
        { period: DAYS_PER_EIGHT_MONTH, count: 8, context: 'month' },
        { period: DAYS_PER_NINE_MONTH, count: 9, context: 'month' },
        { period: DAYS_PER_TEN_MONTH, count: 10, context: 'month' },
        { period: DAYS_PER_ELEVEN_MONTH, count: 11, context: 'month' },
        { period: DAYS_PER_YEAR, count: 1, context: 'year' },
    ];

    if (period) {
        const currentPeriod = PERIODS_ARR.find((item) => item.period === period);

        return { count: currentPeriod?.count || 'NULL', context: currentPeriod?.context || 'NULL' };
    }

    return { count: 'NULL', context: 'NULL' };
};

export const getProductPaymentData = (currentProduct: ICurrentProduct) => {
    const { trial, period, start_price, price } = currentProduct;

    const currentPaymentType = getSubscriptionTitle(trial);
    const paymentType = trial === period ? 'SEVERAL_MONTH_PLAN' : 'ONE_WEEK_PLAN';
    const paymentLabel = getSubscriptionTitle(period);
    const fullPrice = getPriceFromCents(start_price);
    const trialPrice = getPriceFromCents(price);
    const trialLabel = '7-day trial';

    return { paymentType, currentPaymentType, paymentLabel, fullPrice, trialPrice, trialLabel };
};

export const getLegalDisclaimer = ({
    isTimerEnd = false,
    withLegalText = false,
    withNewLegalTextGolf = false,
    withLegalTextGolf5 = false,
    withLegalTextGolf5NoTrial = false,
    withLegalTextGolf13 = false,
    withLegalTextGolf13NoTrial = false,
    isVisible = false,
}): string => {
    // TODO revisit this place once someone have a ticket related to disclaimer on checkout.
    // For now we just have to hide these disclaimers without updating crowdin and main logic
    // Follow up ticket https://app.asana.com/0/1201994806276539/1206350298563353/f
    if (!isVisible) return '';

    switch (true) {
        case withLegalText:
            return isTimerEnd
                ? 'paymentFlow.paymentDisclaimer.newLegalDisclaimerV4TimerEnd'
                : 'paymentFlow.paymentDisclaimer.newLegalDisclaimerV4';

        case withNewLegalTextGolf:
            return isTimerEnd
                ? 'paymentFlow.paymentDisclaimer.newLegalDisclaimerGolfTimerEnd'
                : 'paymentFlow.paymentDisclaimer.newLegalDisclaimerGolf';

        case withLegalTextGolf5:
            return isTimerEnd
                ? 'paymentFlow.checkoutGolf5.disclaimerTrial.TimerOff'
                : 'paymentFlow.checkoutGolf5.disclaimerTrial.TimerOn';

        case withLegalTextGolf5NoTrial:
            return isTimerEnd
                ? 'paymentFlow.checkoutGolf5.newLegalDisclaimerGolf.TimerOff'
                : 'paymentFlow.checkoutGolf5.newLegalDisclaimerGolf';

        case withLegalTextGolf13:
            return isTimerEnd
                ? 'paymentFlow.checkoutGolf13.disclaimerTrial.TimerOff'
                : 'paymentFlow.checkoutGolf13.disclaimerTrial.TimerOn';

        case withLegalTextGolf13NoTrial:
            return isTimerEnd
                ? 'paymentFlow.checkoutGolf13.disclaimer.timerOff'
                : 'paymentFlow.checkoutGolf13.disclaimer.timerOn';

        default:
            return '';
    }
};

export const getTrialFullPrice = ({
    paymentProducts,
    currentProduct,
}: {
    paymentProducts: ICurrentProduct[];
    currentProduct?: ICurrentProduct | null;
}) => {
    const currentProductIndex = paymentProducts.findIndex(({ id }) => id === currentProduct?.id);

    if (currentProductIndex < paymentProducts.length) {
        // next product contains trial's full price
        const productAfterTheTrial = paymentProducts[currentProductIndex + 1];

        return getPriceFromCents(productAfterTheTrial?.start_price);
    } else {
        return HARDCODED_PRICE_FOR_FIRST_TRIAL_PRODUCT_ON_PAYMENTG4FOXTROT16;
    }
};

export const calculateLTV = (productLTV: number, productPeriod: number) => {
    const coefficient =
        productPeriod !== DAYS_PER_MONTH
            ? DEFAULT_LTV_COEFFICIENT
            : Number(localStorage.getItem(LTV_COEFFICIENT_KEY)) || DEFAULT_LTV_COEFFICIENT;

    return getPriceFromCents(Number(productLTV) * coefficient);
};

export const getDiscountPercent = ({ startPrice, price }: { startPrice: number; price: number }) =>
    Math.round((1 - price / startPrice) * 100);

export const getBMI = (current_weight: number | null, tall: number | null, measureSystem: MeasureSystemType) => {
    const converted = new Convert(measureSystem);

    return (current_weight && tall && converted.getBMI(current_weight, tall)) || DEFAULT_BMI;
};

export const getPurchaseOptions: GetPurchaseOptionsType = ({ pageUrl, period, trial }) => {
    const branchName = localStorage.getItem(LS_KEYS.currentBranchName);

    if (pageUrl === 'payment-e1-golf12') return { selectedSystems: [systemNames.amazon] };

    if (isOneMonthProduct({ period, trial }) && branchName === GENERAL_BRANCH)
        return {
            selectedSystems: excludeAnalyticsSystems([systemNames.pixel]),
        };

    return null;
};

export const getMinPriceFromArr = (arr: CurrentProduct[]) => {
    return arr.reduce((prev, cur) => (prev['price'] < cur['price'] ? prev : cur));
};

export const getMaxPriceFromArr = (arr: CurrentProduct[]) => {
    return arr.reduce((prev, cur) => (prev['price'] > cur['price'] ? prev : cur));
};

export const getDiscountFromFirstPayment = (currentProductPeriod: number, products: CurrentProduct[] = []) => {
    const productsByPeriodFromFirstPayment = products.filter((product) => product?.trial === currentProductPeriod);

    if (productsByPeriodFromFirstPayment.length < 2) {
        return 0;
    }

    const minPricedProduct = getMinPriceFromArr(productsByPeriodFromFirstPayment);
    const maxPricedProduct = getMaxPriceFromArr(productsByPeriodFromFirstPayment);

    return getDiscount(maxPricedProduct.price, minPricedProduct.price);
};

export const getLiteral = (isCreditCard = false) => {
    switch (true) {
        case isAndroid:
            return CREDIT_CARD_LITERAL;

        case isIOS:
            return isCreditCard ? CREDIT_CARD_LITERAL : ONE_CLICK_LITERAL;

        default:
            return CREDIT_CARD_LITERAL;
    }
};

export const formatPriceForCountryWithoutCoins = (price: number, currency: string) => {
    if (currency.toUpperCase() === CLP.name) return price / 100;

    return price;
};

export const getContentId = ({ product, price, trialPrice }: IGetContentId) => {
    const { payment_type, product_code, trial, period } = product;

    const periodValue = `period: ${trial}/${period}`;

    const priceValue = `price: ${trialPrice}/${price}`;

    return `${payment_type}: ${product_code} | ${periodValue} | ${priceValue}`;
};
