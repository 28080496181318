import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { Trans, useTranslation } from 'react-i18next';

import { selectUIEffects } from 'redux/uiEffects/selectors';
import { hideModal } from 'redux/uiEffects/actions';

import { SUPPORT_EMAIL } from 'constants/common';

import { Button, Text, Box } from 'libComponents';

import styles from './NotificationModal.module.css';

import { ReactComponent as IconInfo } from './img/issueInfo.svg';
import { ReactComponent as AlertIcon } from './img/alertIcon.svg';

ReactModal.setAppElement('#app');

export default function NotificationModal() {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { issueType, issueDescription, issueTitle, modalIsOpen, issueButtonTitle } = useSelector(selectUIEffects);

    return (
        <ReactModal
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            isOpen={Boolean(modalIsOpen)}
            onRequestClose={() => dispatch(hideModal())}
            contentLabel="Modal"
            className={styles.notificationModal}
            overlayClassName={styles.Overlay}
            bodyOpenClassName={styles.ReactModalBodyOpen}
        >
            <Box mb={20}>
                {issueType === 'ERROR' ? <AlertIcon /> : <IconInfo className={styles.notificationModalIcon} />}
            </Box>
            <Text mb={12} type="h5" text={t(issueTitle || '')} />
            <Text color="text-secondary" type="large-text" mb={16}>
                <Trans i18nKey={issueDescription || ''} components={{ 1: <span className={styles.bold} /> }} />
            </Text>
            <Button
                onClick={() => window.open(`mailto:${SUPPORT_EMAIL}`)}
                text={t(issueButtonTitle || 'basics.gotIt')}
            />
        </ReactModal>
    );
}
